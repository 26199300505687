var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.key}` },
    [
      _c("div", { staticClass: "kt-subheader kt-grid__item" }, [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c(
            "div",
            { staticClass: "kt-subheader__main" },
            [
              _c(
                "h3",
                {
                  staticClass: "kt-subheader__title text-nowrap text-truncate",
                },
                [
                  _c("SVGIcon", {
                    staticClass: "mt-2 mr-3",
                    attrs: { "hex-color": "#0f88ef", name: "progressReports" },
                  }),
                  _vm._v(" Reports "),
                ],
                1
              ),
              _c("SubHeaderMarkingPeriodPicker"),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                "no-center": "",
                opacity: "1",
                blur: "10px",
                show: _vm.encryptionEnabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center pt-4 kt-font-lg" },
                        [
                          _vm._v(
                            " PII is currently encrypted. Please unlock PII to view this page. "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center pt-4" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-brand btn-bold btn-upper mt-4",
                                staticStyle: { "min-width": "160px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrypt()
                                  },
                                },
                              },
                              [_vm._v(" Unlock PII ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__body p-3" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-widget kt-widget--user-profile-3 pb-4",
                        },
                        [
                          _c("div", { staticClass: "kt-widget__top" }, [
                            _c(
                              "div",
                              { staticClass: "kt-widget__content pl-3" },
                              [
                                _c("div", { staticClass: "kt-widget__head" }, [
                                  _c(
                                    "h3",
                                    {
                                      staticClass: "kt-widget__title",
                                      staticStyle: { cursor: "default" },
                                    },
                                    [_vm._v(" Create Progress Report ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__action" },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "nav-item" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "kt-link kt-font-bold nav-link",
                                              class: {
                                                "kt-font-primary kt-font-boldest":
                                                  _vm.progressReportParams
                                                    .reportType ===
                                                  "Course Level",
                                              },
                                              style:
                                                _vm.progressReportParams
                                                  .reportType === "Course Level"
                                                  ? ""
                                                  : "color: gray;",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.progressReportParams.reportType =
                                                    "Course Level"
                                                },
                                              },
                                            },
                                            [_vm._v(" Course Level ")]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "nav-item" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "kt-link kt-link--state kt-link--success kt-font-bold nav-link",
                                              class: {
                                                "kt-font-success kt-font-boldest":
                                                  _vm.progressReportParams
                                                    .reportType ===
                                                  "School Level",
                                              },
                                              style:
                                                _vm.progressReportParams
                                                  .reportType === "School Level"
                                                  ? ""
                                                  : "color: gray;",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.progressReportParams.reportType =
                                                    "School Level"
                                                },
                                              },
                                            },
                                            [_vm._v(" School Level ")]
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "kt-portlet__content" }, [
                        _c("div", { staticClass: "kt-section pl-4 pr-4" }, [
                          _c(
                            "div",
                            { staticClass: "kt-section__desc text-muted" },
                            [
                              _vm._v(
                                " Configure what to include in Progress Report "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "kt-section__content" }, [
                            _c("div", { staticClass: "row" }, [
                              _vm.progressReportParams.reportType ===
                              "Course Level"
                                ? _c("div", { staticClass: "col-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "kt-checkbox-list" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                value:
                                                  "Lists each assignment, completion status, grade and scaled score",
                                                expression:
                                                  "'Lists each assignment, completion status, grade and scaled score'",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "kt-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.progressReportParams
                                                      .showAssignments,
                                                  expression:
                                                    "progressReportParams.showAssignments",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.progressReportParams
                                                    .showAssignments
                                                )
                                                  ? _vm._i(
                                                      _vm.progressReportParams
                                                        .showAssignments,
                                                      null
                                                    ) > -1
                                                  : _vm.progressReportParams
                                                      .showAssignments,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.progressReportParams
                                                        .showAssignments,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showAssignments",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showAssignments",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.progressReportParams,
                                                      "showAssignments",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" Assignments "),
                                            _c("span"),
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                value:
                                                  "Includes the most recent non-present course attendance records",
                                                expression:
                                                  "'Includes the most recent non-present course attendance records'",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "kt-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.progressReportParams
                                                      .showAttendance,
                                                  expression:
                                                    "progressReportParams.showAttendance",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.progressReportParams
                                                    .showAttendance
                                                )
                                                  ? _vm._i(
                                                      _vm.progressReportParams
                                                        .showAttendance,
                                                      null
                                                    ) > -1
                                                  : _vm.progressReportParams
                                                      .showAttendance,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.progressReportParams
                                                        .showAttendance,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showAttendance",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showAttendance",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.progressReportParams,
                                                      "showAttendance",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" Attendance "),
                                            _c("span"),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.progressReportParams.reportType ===
                              "Course Level"
                                ? _c("div", { staticClass: "col-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "kt-checkbox-list" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                value:
                                                  "Include section with average for each course",
                                                expression:
                                                  "'Include section with average for each course'",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "kt-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.progressReportParams
                                                      .showCourseAverage,
                                                  expression:
                                                    "progressReportParams.showCourseAverage",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.progressReportParams
                                                    .showCourseAverage
                                                )
                                                  ? _vm._i(
                                                      _vm.progressReportParams
                                                        .showCourseAverage,
                                                      null
                                                    ) > -1
                                                  : _vm.progressReportParams
                                                      .showCourseAverage,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.progressReportParams
                                                        .showCourseAverage,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showCourseAverage",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showCourseAverage",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.progressReportParams,
                                                      "showCourseAverage",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" Course Average "),
                                            _c("span"),
                                          ]
                                        ),
                                        _c(
                                          "label",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip.hover",
                                                value:
                                                  "Include table of grading categories with category averages and scaled scores",
                                                expression:
                                                  "'Include table of grading categories with category averages and scaled scores'",
                                                modifiers: { hover: true },
                                              },
                                            ],
                                            staticClass: "kt-checkbox",
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.progressReportParams
                                                      .showGradeBreakdown,
                                                  expression:
                                                    "progressReportParams.showGradeBreakdown",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.progressReportParams
                                                    .showGradeBreakdown
                                                )
                                                  ? _vm._i(
                                                      _vm.progressReportParams
                                                        .showGradeBreakdown,
                                                      null
                                                    ) > -1
                                                  : _vm.progressReportParams
                                                      .showGradeBreakdown,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.progressReportParams
                                                        .showGradeBreakdown,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showGradeBreakdown",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.progressReportParams,
                                                          "showGradeBreakdown",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.progressReportParams,
                                                      "showGradeBreakdown",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                            _vm._v(" Grade Breakdown "),
                                            _c("span"),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "kt-checkbox-list" }, [
                                  _c(
                                    "label",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value:
                                            "Includes teacher defined comments for course",
                                          expression:
                                            "'Includes teacher defined comments for course'",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass: "kt-checkbox",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.progressReportParams
                                                .showComments,
                                            expression:
                                              "progressReportParams.showComments",
                                          },
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.progressReportParams
                                              .showComments
                                          )
                                            ? _vm._i(
                                                _vm.progressReportParams
                                                  .showComments,
                                                null
                                              ) > -1
                                            : _vm.progressReportParams
                                                .showComments,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.progressReportParams
                                                  .showComments,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.progressReportParams,
                                                    "showComments",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.progressReportParams,
                                                    "showComments",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.progressReportParams,
                                                "showComments",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" Comments "),
                                      _c("span"),
                                    ]
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "mt-2" }, [
                              _c("div", { staticClass: "kt-checkbox-list" }, [
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value:
                                          "Include section with average for each course",
                                        expression:
                                          "'Include section with average for each course'",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass: "kt-checkbox",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.progressReportParams
                                              .showAnnouncement,
                                          expression:
                                            "progressReportParams.showAnnouncement",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.progressReportParams
                                            .showAnnouncement
                                        )
                                          ? _vm._i(
                                              _vm.progressReportParams
                                                .showAnnouncement,
                                              null
                                            ) > -1
                                          : _vm.progressReportParams
                                              .showAnnouncement,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.progressReportParams
                                                .showAnnouncement,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "showAnnouncement",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "showAnnouncement",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.progressReportParams,
                                              "showAnnouncement",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" School Announcement "),
                                    _c("span"),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value:
                                          "Students can view report on portfolio",
                                        expression:
                                          "'Students can view report on portfolio'",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass:
                                      "kt-checkbox kt-checkbox--bold kt-checkbox--success",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.progressReportParams
                                              .showOnPortfolio,
                                          expression:
                                            "progressReportParams.showOnPortfolio",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.progressReportParams
                                            .showOnPortfolio
                                        )
                                          ? _vm._i(
                                              _vm.progressReportParams
                                                .showOnPortfolio,
                                              null
                                            ) > -1
                                          : _vm.progressReportParams
                                              .showOnPortfolio,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.progressReportParams
                                                .showOnPortfolio,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "showOnPortfolio",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "showOnPortfolio",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.progressReportParams,
                                              "showOnPortfolio",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" Visible to Students "),
                                    _c("span"),
                                  ]
                                ),
                                _c(
                                  "label",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value:
                                          "Notifies students/guardians a progress report was made",
                                        expression:
                                          "'Notifies students/guardians a progress report was made'",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass:
                                      "kt-checkbox kt-checkbox--bold kt-checkbox--success",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.progressReportParams
                                              .notifyToEmail,
                                          expression:
                                            "progressReportParams.notifyToEmail",
                                        },
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.progressReportParams.notifyToEmail
                                        )
                                          ? _vm._i(
                                              _vm.progressReportParams
                                                .notifyToEmail,
                                              null
                                            ) > -1
                                          : _vm.progressReportParams
                                              .notifyToEmail,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.progressReportParams
                                                .notifyToEmail,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "notifyToEmail",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.progressReportParams,
                                                  "notifyToEmail",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.progressReportParams,
                                              "notifyToEmail",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" Notify to Email "),
                                    _c("span"),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            [
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "kt-menu__submenu pt-3",
                                  model: {
                                    value:
                                      _vm.progressReportParams.showAnnouncement,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.progressReportParams,
                                        "showAnnouncement",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "progressReportParams.showAnnouncement",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(" Progress Report Announcement "),
                                  ]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.progressReportParams.announcement,
                                        expression:
                                          "progressReportParams.announcement",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { rows: "3" },
                                    domProps: {
                                      value:
                                        _vm.progressReportParams.announcement,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.progressReportParams,
                                          "announcement",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "pull-right" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-warning btn-sm btn-bold btn-upper",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.confirmGenerateProgressReport.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "la la-archive" }),
                              _vm._v(" Generate "),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__body p-3" }, [
                      _vm.reportCard && !_vm.ReGenerating
                        ? _c(
                            "div",
                            {
                              staticClass: "kt-widget kt-widget--project-1",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openReport(_vm.reportCard)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-widget__head d-flex" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__label" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__info kt-padding-0 kt-margin-l-15",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "kt-widget__title",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.openReport(
                                                    _vm.reportCard
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Report Card ")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "kt-widget__desc" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "kt-badge kt-badge--unified-primary kt-badge--inline",
                                                },
                                                [
                                                  _vm._v(
                                                    " Report Cards Generated "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mt-4" },
                                                [
                                                  _vm._v(" On "),
                                                  _c("code", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.reportCard.created
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__toolbar" },
                                    [
                                      _vm.$_userMixins_isSchoolAdmin
                                        ? _c("span", [
                                            !_vm.reportCard.showOnPortfolio
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        value:
                                                          "Releases report card to students/guardians",
                                                        expression:
                                                          "'Releases report card to students/guardians'",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-warning btn-sm btn-bold mr-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.releaseReportCard(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "la la-certificate",
                                                    }),
                                                    _vm._v(" Release "),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.reportCard.showOnPortfolio
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover",
                                                        value:
                                                          "Hides report card from students/guardians",
                                                        expression:
                                                          "'Hides report card from students/guardians'",
                                                        modifiers: {
                                                          hover: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn btn-clean btn-sm btn-bold mr-2",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.releaseReportCard(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "la la-user-secret",
                                                    }),
                                                    _vm._v(" Hide "),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary btn-sm btn-bold",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    _vm.ReGenerating = true
                                                  },
                                                },
                                              },
                                              [_vm._v(" Re-Generate ")]
                                            ),
                                          ])
                                        : _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-clean btn-sm btn-icon btn-icon-md",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.openReport(
                                                    _vm.reportCard
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "flaticon-download",
                                              }),
                                            ]
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "kt-widget kt-widget--user-profile-3 pb-4",
                            },
                            [
                              _c("div", { staticClass: "kt-widget__top" }, [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget__content pl-3" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-widget__head" },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            staticClass: "kt-widget__title",
                                            staticStyle: { cursor: "default" },
                                          },
                                          [_vm._v(" Report Card ")]
                                        ),
                                        _vm.$_userMixins_isSchoolAdmin &&
                                        _vm.markingPeriod &&
                                        _vm.markingPeriod.isConfigured
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "kt-widget__action",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary btn-sm btn-upper",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.previewReportCard.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-eye",
                                                    }),
                                                    _vm._v(" Preview "),
                                                  ]
                                                ),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-warning btn-sm btn-upper",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.confirmGenerateReportCard.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "la la-certificate",
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.ReGenerating
                                                            ? "Re-Generate"
                                                            : "Generate"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                      _vm.reportCard && !_vm.ReGenerating
                        ? _c("div", { staticClass: "kt-portlet__content" }, [
                            _c("div", { staticClass: "pull-right" }, [
                              _vm._v(
                                " Generated By " +
                                  _vm._s(_vm.reportCard.author) +
                                  " "
                              ),
                            ]),
                          ])
                        : _vm.$_userMixins_isSchoolAdmin &&
                          _vm.markingPeriod &&
                          _vm.markingPeriod.isConfigured
                        ? _c("div", { staticClass: "kt-portlet__content" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "kt-section__content pl-3 pr-3",
                                },
                                [
                                  _vm.markingPeriod.isBeforeSubmissionWindow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-solid-danger alert-bold",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " Report Card submission window has not started "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.markingPeriod.isWithinSubmissionWindow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-solid-warning alert-bold",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " Report Card submission window ongoing "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.markingPeriod.isAfterSubmissionWindow
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-solid-success alert-bold",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " Report Card submission window closed "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "form-group pl-3 pr-3" }, [
                              _c("div", [_vm._v(" Report Card Announcement ")]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reportCardAnnouncement,
                                    expression: "reportCardAnnouncement",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { rows: "3" },
                                domProps: { value: _vm.reportCardAnnouncement },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.reportCardAnnouncement =
                                      $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.ReGenerating
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "kt-link kt-link--state kt-link--danger kt-font-bold mt-2 pull-right",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.ReGenerating = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel Re-Generation")]
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm.$_userMixins_isSchoolAdmin &&
                          _vm.markingPeriod &&
                          !_vm.markingPeriod.isConfigured
                        ? _c("div", { staticClass: "kt-portlet__content" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert alert-solid-warning alert-bold",
                                    },
                                    [
                                      _c("div", { staticClass: "alert-icon" }, [
                                        _c("i", {
                                          staticClass: "flaticon-warning",
                                        }),
                                      ]),
                                      _c("div", { staticClass: "alert-text" }, [
                                        _vm._v(
                                          " Marking Period has not been configured for Report Cards by admin "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "kt-portlet__content" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "alert alert-solid-warning alert-bold",
                                    },
                                    [
                                      _c("div", { staticClass: "alert-icon" }, [
                                        _c("i", {
                                          staticClass: "flaticon-warning",
                                        }),
                                      ]),
                                      _c("div", { staticClass: "alert-text" }, [
                                        _vm._v(
                                          " Report Cards have not been generated/configured by administrator "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.filteredReports, function (report, idx) {
                  return _c(
                    "div",
                    {
                      key: `report_${report.reportId}_${idx}`,
                      staticClass: "col-lg-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-portlet kt-portlet--solid kt-portletk-portlet--height-fluid",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.openReport(report)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-portlet__head kt-portlet__head--noborder",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-portlet__head-label" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-portlet__head-title",
                                      class: {
                                        "kt-link kt-link--state kt-link--primary":
                                          report.reportParams.reportType ===
                                          "Course Level",
                                        "kt-link kt-link--state kt-link--success":
                                          report.reportParams.reportType ===
                                          "School Level",
                                      },
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openReport(report)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(report.reportType) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-portlet__head-toolbar" },
                                [
                                  report.generatedRole === "School Admin"
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-badge kt-badge--warning kt-badge--inline mr-2",
                                            staticStyle: { cursor: "default" },
                                          },
                                          [_vm._v("Admin")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "kt-portlet__body pt-0 mt-0" },
                            [
                              _c("div", [
                                _c("div", { staticClass: "kt-font-bold" }, [
                                  _vm._v(" Generated on "),
                                  _c("br"),
                                  _c("code", [_vm._v(_vm._s(report.created))]),
                                ]),
                                _c("div", { staticClass: "mt-3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--bold kt-badge--inline",
                                      class: {
                                        "kt-badge--success":
                                          report.reportParams.reportType ===
                                          "School Level",
                                        "kt-badge--primary":
                                          report.reportParams.reportType ===
                                          "Course Level",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            report.reportParams.reportType
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--bold kt-badge--inline ml-3",
                                      class: {
                                        "kt-badge--unified-primary":
                                          report.showOnPortfolio,
                                        "kt-badge--unified-warning":
                                          !report.showOnPortfolio,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            report.showOnPortfolio
                                              ? "Visible"
                                              : "Hidden"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "pull-right" }, [
                                  _vm._v(" By " + _vm._s(report.author) + " "),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _c("ConfirmModal", {
        ref: "confirmModal",
        attrs: {
          "confirm-modal-visible": _vm.confirmModalVisible,
          "cancel-function": _vm.cancelConfirm,
          "confirm-function": _vm.confirmFunction,
          processing: _vm.generating,
          header: _vm.confirmHeader,
          message: _vm.confirmMessage,
          color: _vm.confirmColor,
        },
      }),
      _c("ReportSearchModal", {
        ref: "reportSearchModal",
        attrs: {
          "report-search-visible": _vm.reportSearchVisible,
          "close-report-search": _vm.closeReportSearch,
          "remove-report": _vm.removeReport,
          report: _vm.selectedReport,
          "open-report-pdf": _vm.openReportPdf,
          courses: _vm.courses,
          "home-rooms": _vm.homeRooms,
          "grade-levels": _vm.gradeLevels,
        },
      }),
      _c("PDFViewModal", { ref: "pdfViewModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }