<template>
<div :key="`key_${key}`">
    <div class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title text-nowrap text-truncate">
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="progressReports"
                    />
                    Reports
                </h3>

                <SubHeaderMarkingPeriodPicker />
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to view this page.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div class="row">
                <div class="col-lg-6">
                    <div class="kt-portlet">
                        <div class="kt-portlet__body p-3">
                            <div class="kt-widget kt-widget--user-profile-3 pb-4">
                                <div class="kt-widget__top">
                                    <div class="kt-widget__content pl-3">
                                        <div class="kt-widget__head">
                                            <h3
                                                class="kt-widget__title"
                                                style="cursor: default;"
                                            >
                                                Create Progress Report
                                            </h3>
                                            <div class="kt-widget__action">
                                                <div class="row">
                                                    <div class="nav-item">
                                                        <span
                                                            class="kt-link kt-font-bold nav-link"
                                                            :style="progressReportParams.reportType === 'Course Level' ? '' : 'color: gray;'"
                                                            :class="{ 'kt-font-primary kt-font-boldest': progressReportParams.reportType === 'Course Level'}"
                                                            @click.stop.prevent="progressReportParams.reportType = 'Course Level'"
                                                        >

                                                            Course Level
                                                        </span>
                                                    </div>
                                                    <div class="nav-item">
                                                        <span
                                                            class="kt-link kt-link--state kt-link--success kt-font-bold nav-link"
                                                            :style="progressReportParams.reportType === 'School Level' ? '' : 'color: gray;'"
                                                            :class="{ 'kt-font-success kt-font-boldest': progressReportParams.reportType === 'School Level'}"
                                                            @click.stop.prevent="progressReportParams.reportType = 'School Level'"
                                                        >

                                                            School Level
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-portlet__content">
                                <div class="kt-section pl-4 pr-4">
                                    <div class="kt-section__desc text-muted">
                                        Configure what to include in Progress Report
                                    </div>
                                    <div class="kt-section__content">
                                        <div class="row">
                                            <div
                                                v-if="progressReportParams.reportType === 'Course Level'"
                                                class="col-4"
                                            >
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Lists each assignment, completion status, grade and scaled score'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showAssignments"
                                                            type="checkbox"
                                                        > Assignments
                                                        <span />
                                                    </label>
                                                    <label
                                                        v-b-tooltip.hover="'Includes the most recent non-present course attendance records'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showAttendance"
                                                            type="checkbox"
                                                        > Attendance
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                v-if="progressReportParams.reportType === 'Course Level'"
                                                class="col-4"
                                            >
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Include section with average for each course'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showCourseAverage"
                                                            type="checkbox"
                                                        > Course Average
                                                        <span />
                                                    </label>
                                                    <label
                                                        v-b-tooltip.hover="'Include table of grading categories with category averages and scaled scores'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showGradeBreakdown"
                                                            type="checkbox"
                                                        > Grade Breakdown
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="kt-checkbox-list">
                                                    <label
                                                        v-b-tooltip.hover="'Includes teacher defined comments for course'"
                                                        class="kt-checkbox"
                                                    >
                                                        <input
                                                            v-model="progressReportParams.showComments"
                                                            type="checkbox"
                                                        > Comments
                                                        <span />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="kt-checkbox-list">
                                                <label
                                                    v-b-tooltip.hover="'Include section with average for each course'"
                                                    class="kt-checkbox"
                                                >
                                                    <input
                                                        v-model="progressReportParams.showAnnouncement"
                                                        type="checkbox"
                                                    > School Announcement
                                                    <span />
                                                </label>
                                                <label
                                                    v-b-tooltip.hover="'Students can view report on portfolio'"
                                                    class="kt-checkbox kt-checkbox--bold kt-checkbox--success"
                                                >
                                                    <input
                                                        v-model="progressReportParams.showOnPortfolio"
                                                        type="checkbox"
                                                    > Visible to Students
                                                    <span />
                                                </label>
                                                <label
                                                    v-b-tooltip.hover="'Notifies students/guardians a progress report was made'"
                                                    class="kt-checkbox kt-checkbox--bold kt-checkbox--success"
                                                >
                                                    <input
                                                        v-model="progressReportParams.notifyToEmail"
                                                        type="checkbox"
                                                    > Notify to Email
                                                    <span />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <b-collapse
                                            v-model="progressReportParams.showAnnouncement"
                                            class="kt-menu__submenu pt-3"
                                        >
                                            <div>
                                                Progress Report Announcement
                                            </div>
                                            <textarea
                                                v-model="progressReportParams.announcement"
                                                class="form-control"
                                                rows="3"
                                            />
                                        </b-collapse>
                                    </div>
                                </div>
                                <div class="pull-right">
                                    <button
                                        type="button"
                                        class="btn btn-warning btn-sm btn-bold btn-upper"
                                        @click.stop.prevent="confirmGenerateProgressReport"
                                    >
                                        <i class="la la-archive" />
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="kt-portlet">
                        <div class="kt-portlet__body p-3">
                            <div
                                v-if="reportCard && !ReGenerating"
                                class="kt-widget kt-widget--project-1"
                                style="cursor: pointer;"
                                @click.stop.prevent="openReport(reportCard)"
                            >
                                <div class="kt-widget__head d-flex">
                                    <div class="kt-widget__label">
                                        <div class="kt-widget__info kt-padding-0 kt-margin-l-15">
                                            <a
                                                href="#"
                                                class="kt-widget__title"
                                                @click.stop.prevent="openReport(reportCard)"
                                            >
                                                Report Card
                                            </a>
                                            <span class="kt-widget__desc">
                                                <span class="kt-badge kt-badge--unified-primary kt-badge--inline">
                                                    Report Cards Generated
                                                </span>
                                                <div class="mt-4">
                                                    On <code>{{ reportCard.created }}</code>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="kt-widget__toolbar">
                                        <span v-if="$_userMixins_isSchoolAdmin">
                                            <button
                                                v-if="!reportCard.showOnPortfolio"
                                                v-b-tooltip.hover="'Releases report card to students/guardians'"
                                                type="button"
                                                class="btn btn-warning btn-sm btn-bold mr-2"
                                                @click.stop.prevent="releaseReportCard(true)"
                                            >
                                                <i class="la la-certificate" />
                                                Release
                                            </button>
                                            <button
                                                v-if="reportCard.showOnPortfolio"
                                                v-b-tooltip.hover="'Hides report card from students/guardians'"
                                                type="button"
                                                class="btn btn-clean btn-sm btn-bold mr-2"
                                                @click.stop.prevent="releaseReportCard(false)"
                                            >
                                                <i class="la la-user-secret" />
                                                Hide
                                            </button>
                                            <button
                                                type="button"
                                                class="btn btn-primary btn-sm btn-bold"
                                                @click.stop.prevent="ReGenerating = true"
                                            >
                                                Re-Generate
                                            </button>
                                        </span>
                                        <button
                                            v-else
                                            class="btn btn-clean btn-sm btn-icon btn-icon-md"
                                            @click.stop.prevent="openReport(reportCard)"
                                        >
                                            <i class="flaticon-download" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="kt-widget kt-widget--user-profile-3 pb-4"
                            >
                                <div class="kt-widget__top">
                                    <div class="kt-widget__content pl-3">
                                        <div class="kt-widget__head">
                                            <h3
                                                class="kt-widget__title"
                                                style="cursor: default;"
                                            >
                                                Report Card
                                            </h3>
                                            <div
                                                v-if="$_userMixins_isSchoolAdmin && markingPeriod && markingPeriod.isConfigured"
                                                class="kt-widget__action"
                                            >
                                                <button
                                                    type="button"
                                                    class="btn btn-secondary btn-sm btn-upper"
                                                    @click.stop.prevent="previewReportCard"
                                                >
                                                    <i class="fa fa-eye" />
                                                    Preview
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-warning btn-sm btn-upper"
                                                    @click.stop.prevent="confirmGenerateReportCard"
                                                >
                                                    <i class="la la-certificate" />
                                                    {{ ReGenerating ? 'Re-Generate' : 'Generate' }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="reportCard && !ReGenerating"
                                class="kt-portlet__content"
                            >
                                <div class="pull-right">
                                    Generated By {{ reportCard.author }}
                                </div>
                            </div>
                            <div
                                v-else-if="$_userMixins_isSchoolAdmin && markingPeriod && markingPeriod.isConfigured"
                                class="kt-portlet__content"
                            >
                                <div class="kt-section">
                                    <div class="kt-section__content pl-3 pr-3">
                                        <div
                                            v-if="markingPeriod.isBeforeSubmissionWindow"
                                            class="alert alert-solid-danger alert-bold"
                                        >
                                            <div class="alert-text">
                                                Report Card submission window has not started
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="markingPeriod.isWithinSubmissionWindow"
                                            class="alert alert-solid-warning alert-bold"
                                        >
                                            <div class="alert-text">
                                                Report Card submission window ongoing
                                            </div>
                                        </div>
                                        <div
                                            v-else-if="markingPeriod.isAfterSubmissionWindow"
                                            class="alert alert-solid-success alert-bold"
                                        >
                                            <div class="alert-text">
                                                Report Card submission window closed
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group pl-3 pr-3">
                                    <div>
                                        Report Card Announcement
                                    </div>
                                    <textarea
                                        v-model="reportCardAnnouncement"
                                        class="form-control"
                                        rows="3"
                                    />
                                    <a
                                        v-if="ReGenerating"
                                        href="#"
                                        class="kt-link kt-link--state kt-link--danger kt-font-bold mt-2 pull-right"
                                        @click.stop.prevent="ReGenerating = false"
                                    >Cancel Re-Generation</a>
                                </div>
                            </div>
                            <div
                                v-else-if="$_userMixins_isSchoolAdmin && markingPeriod && !markingPeriod.isConfigured"
                                class="kt-portlet__content"
                            >
                                <div class="kt-section">
                                    <div class="kt-section__content">
                                        <div class="alert alert-solid-warning alert-bold">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning" />
                                            </div>
                                            <div class="alert-text">
                                                Marking Period has not been configured for Report Cards by admin
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="kt-portlet__content"
                            >
                                <div class="kt-section">
                                    <div class="kt-section__content">
                                        <div class="alert alert-solid-warning alert-bold">
                                            <div class="alert-icon">
                                                <i class="flaticon-warning" />
                                            </div>
                                            <div class="alert-text">
                                                Report Cards have not been generated/configured by administrator
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div
                    v-for="(report, idx) in filteredReports"
                    :key="`report_${report.reportId}_${idx}`"
                    class="col-lg-4"
                >
                    <div
                        class="kt-portlet kt-portlet--solid kt-portletk-portlet--height-fluid"
                        style="cursor: pointer;"
                        @click.stop.prevent="openReport(report)"
                    >
                        <div class="kt-portlet__head kt-portlet__head--noborder">
                            <div class="kt-portlet__head-label">
                                <a
                                    href="#"
                                    class="kt-portlet__head-title"
                                    :class="{
                                        'kt-link kt-link--state kt-link--primary': report.reportParams.reportType === 'Course Level',
                                        'kt-link kt-link--state kt-link--success': report.reportParams.reportType === 'School Level',
                                    }"
                                    @click.stop.prevent="openReport(report)"
                                >
                                    {{ report.reportType }}
                                </a>
                            </div>
                            <div class="kt-portlet__head-toolbar">
                                <div v-if="report.generatedRole === 'School Admin'">
                                    <span
                                        class="kt-badge kt-badge--warning kt-badge--inline mr-2"
                                        style="cursor: default;"
                                    >Admin</span>
                                </div>
                            </div>
                        </div>
                        <div class="kt-portlet__body pt-0 mt-0">
                            <div>
                                <div class="kt-font-bold">
                                    Generated on <br><code>{{ report.created }}</code>
                                </div>

                                <div class="mt-3">
                                    <span
                                        class="kt-badge kt-badge--bold kt-badge--inline"
                                        :class="{
                                            'kt-badge--success': report.reportParams.reportType === 'School Level',
                                            'kt-badge--primary': report.reportParams.reportType === 'Course Level',
                                        }"
                                    >
                                        {{ report.reportParams.reportType }}
                                    </span>

                                    <span
                                        class="kt-badge kt-badge--bold kt-badge--inline ml-3"
                                        :class="{
                                            'kt-badge--unified-primary': report.showOnPortfolio,
                                            'kt-badge--unified-warning': !report.showOnPortfolio,
                                        }"
                                    >
                                        {{ report.showOnPortfolio ? 'Visible' : 'Hidden' }}
                                    </span>
                                </div>

                                <div class="pull-right">
                                    By {{ report.author }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>

    <ConfirmModal
        ref="confirmModal"
        :confirm-modal-visible="confirmModalVisible"
        :cancel-function="cancelConfirm"
        :confirm-function="confirmFunction"
        :processing="generating"
        :header="confirmHeader"
        :message="confirmMessage"
        :color="confirmColor"
    />

    <ReportSearchModal
        ref="reportSearchModal"
        :report-search-visible="reportSearchVisible"
        :close-report-search="closeReportSearch"
        :remove-report="removeReport"
        :report="selectedReport"
        :open-report-pdf="openReportPdf"
        :courses="courses"
        :home-rooms="homeRooms"
        :grade-levels="gradeLevels"
    />

    <PDFViewModal ref="pdfViewModal" />
</div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as network from '../network';
import Types from '../store/Types';
import SubHeaderMarkingPeriodPicker from '../components/SubHeaderMarkingPeriodPicker.vue';
import PDFViewModal from '../components/PDFViewModal.vue';
import ConfirmModal from '../components/ConfirmModal.vue';
import ReportSearchModal from '../components/ReportSearchModal.vue';
import userMixins from '../store/mixins/userMixins';
import reportCardMixins from '../store/mixins/reportCardMixins';
import { getStudentGradeLevels, getStudentHomeRooms } from '../store/mixins/studentMixins';
import { getCourses, CourseFilter } from '../store/mixins/courseMixins';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
    name: 'Reports',
    components: {
        SubHeaderMarkingPeriodPicker,
        PDFViewModal,
        ConfirmModal,
        ReportSearchModal,
    },
    mixins: [userMixins, reportCardMixins],
    data() {
        return {
            key: 0,
            reports: [],
            generating: false,
            ReGenerating: false,
            selectedReport: null,
            reportSearchVisible: false,
            reportCardAnnouncement: '',
            confirmHeader: '',
            confirmMessage: '',
            confirmColor: 'Blue',
            confirmFunction: () => {},
            confirmModalVisible: false,
            progressReportParams: {
                generatedRole: 'School Admin',
                reportType: 'School Level',
                showAssignments: true,
                showAttendance: true,
                showCourseAverage: true,
                showGradeBreakdown: true,
                showComments: true,
                showAnnouncement: false,
                announcement: '',
                showOnPortfolio: false,
                notifyToEmail: false,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
            schoolStaffId: (state) => state.user.school.schoolStaffId,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        markingPeriods() {
            return this.$_reportCardMixins_getMarkingPeriods.filter((mp) => !mp.deleted);
        },
        markingPeriod() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        reportCard() {
            const { reports, schoolTermMarkingPeriodId } = this;
            if (!reports || !reports.length) return null;
            const reportCard = reports.find((r) => r.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId && r.reportType === 'Report Card') || null;
            if (!reportCard) return null;
            return {
                ...reportCard,
                created: moment(reportCard.created).utc().format('MM/DD/YYYY hh:mm A'),
            };
        },
        courses() {
            const { database } = this.$store.state;
            return getCourses(database, new CourseFilter({ }));
        },
        homeRooms() {
            const { database } = this.$store.state;
            return getStudentHomeRooms(database);
        },
        gradeLevels() {
            const { database } = this.$store.state;
            return getStudentGradeLevels(database);
        },
        filteredReports() {
            const { reports, schoolTermMarkingPeriodId } = this;
            return reports.filter((r) => r.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId && r.reportType === 'Progress Report').map((r) => ({
                ...r,
                created: moment(r.created).utc().format('YYYY-MM-DD HH:mm A'),
            })).sort((a, b) => moment(b.created).diff(a.created));
        },
        sampleCourses() {
            return [
                {
                    name: 'English',
                    teacher: 'Smith',
                    courseId: 'ENG-01',
                },
                {
                    name: 'Math',
                    teacher: 'Jones',
                    courseId: 'MAT-01',
                },
                {
                    name: 'Science',
                    teacher: 'Johnson',
                    courseId: 'SCI-01',
                },
                {
                    name: 'History',
                    teacher: 'Williams',
                    courseId: 'HIS-01',
                },
            ];
        },
        definition() {
            const {
                school, markingPeriods, sampleCourses, reportCardAnnouncement,
            } = this;
            const {
                name, schoolName, externalSchoolId,
            } = school;
            const date = moment().format('MM/DD/YYYY hh:mm A');
            const courseTableWidth = 100 - (markingPeriods.length * 5);

            return {
                footer: {
                    table: {
                        headerRows: 0,
                        widths: ['*'],
                        body: [
                            [
                                {
                                    border: [false, false, false, false],
                                    columns: [
                                        {
                                            text: '',
                                            width: '50%',
                                        },
                                        {
                                            text: 'Parent Signature',
                                            width: '35%',
                                        },
                                        {
                                            text: 'Date',
                                            width: '15%',
                                        },
                                    ],
                                },
                            ],
                            [
                                {
                                    border: [false, false, false, false],
                                    fillColor: '#f4f5f4',
                                    columns: [
                                        {
                                            text: [
                                                {
                                                    text: `${date}`,
                                                },
                                            ],
                                        },
                                        {
                                            text: 'SyncGrades',
                                            alignment: 'right',
                                        },
                                    ],
                                    margin: [10, 0],
                                },
                            ],
                        ],
                    },
                },
                content: [
                    {
                        table: {
                            headerRows: 0,
                            widths: ['*'],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        fillColor: '#f4f5f4',
                                        columns: [
                                            {
                                                text: [
                                                    {
                                                        text: `${schoolName}\n(${externalSchoolId})\n`,
                                                        style: 'header',
                                                    },
                                                ],
                                                width: '40%',
                                                alignment: 'left',
                                            },
                                            {
                                                text: [
                                                    `Report Card for ${name}, ${'Marking Period #'}\n`,
                                                    {
                                                        text: `${'Last Name, First Name'}\n${'000-000-000'}\n`,
                                                        style: 'studentName',
                                                    },
                                                    {
                                                        text: 'Grade Level: ',
                                                        style: 'bold',
                                                    },
                                                    `${'#'}`,
                                                ],
                                                alignment: 'right',
                                                width: '*',
                                            },
                                        ],
                                        margin: [0, 0, 0, 15],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        table: {
                            headerRows: 0,
                            width: ['*'],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: [
                                            {
                                                text: 'SCHOOL ANNOUNCEMENT',
                                                style: ['courseName', 'gray'],
                                            },
                                        ],
                                        margin: [0, 10, 0, 0],
                                    },
                                ],
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: [
                                            {
                                                text: `${reportCardAnnouncement || 'No School Announcement'}`,
                                                style: reportCardAnnouncement ? ['bold', 'italic'] : ['italic'],
                                            },
                                        ],
                                        margin: [0, 0, 0, 50],
                                    },
                                ],
                            ],
                        },
                    },
                    {
                        columns: [
                            {
                                text: '',
                                width: `${courseTableWidth - 7.5}%`,
                            },
                            {
                                text: [
                                    {
                                        text: 'MARKING PERIODS\n',
                                        style: 'bold',
                                    },
                                    'Grade',
                                ],
                                alignment: 'center',
                                width: `${(markingPeriods.length * 5) + 7.5}%`,
                            },
                        ],
                    },
                    {
                        style: 'genericTable',
                        table: {
                            headerRows: 0,
                            widths: [
                                `${courseTableWidth}%`,
                                ...markingPeriods.map((mp) => '5%'),
                            ],
                            body: [
                                [
                                    {
                                        border: [false, false, false, false],
                                        text: '',
                                    },
                                    ...markingPeriods.map((mp) => ({
                                        border: [false, false, false, false],
                                        fillColor: '#f4f5f4',
                                        margin: [
                                            8,
                                            8,
                                            8,
                                            8,
                                        ],
                                        text: [{
                                            text: mp.markingPeriod,
                                            style: ['courseName', 'gray'],
                                        }],
                                        alignment: 'center',
                                    })),
                                ],
                                ...sampleCourses.map((c) => [
                                    {
                                        border: [false, false, false, false],
                                        padding: [6, 3, 6, 3],
                                        table: {
                                            headerRows: 0,
                                            widths: ['100%'],
                                            body: [
                                                [
                                                    {
                                                        border: [false, false, false, false],
                                                        fillColor: '#f4f5f4',
                                                        text: [
                                                            {
                                                                text: `${c.name} / ${c.teacher}\n`,
                                                                style: 'courseName',
                                                            },
                                                            {
                                                                text: `${c.courseId}`,
                                                                style: 'italic',
                                                            },
                                                        ],
                                                    },
                                                ],
                                                [
                                                    {
                                                        border: [false, false, false, false],
                                                        text: [
                                                            {
                                                                text: `Teacher Comments: ${'Comment 1'} / ${'Comment 2'} / ${'Comment 3'}`,
                                                                style: 'italic',
                                                            },
                                                        ],
                                                    },
                                                ],
                                            ],
                                        },
                                    },
                                    ...markingPeriods.map((mp) => ({
                                        border: [false, false, false, false],
                                        fillColor: null,
                                        padding: [6, 3, 6, 3],
                                        text: [{
                                            text: '-',
                                            style: 'grade',
                                        }],
                                        alignment: 'center',
                                    })),
                                ]),
                            ],
                        },
                    },
                ],
                styles: {
                    genericTable: {
                        margin: [
                            4,
                            10,
                            4,
                            10,
                        ],
                    },
                    body: {
                        fontSize: 9,
                    },
                    singleRow: {
                        fontSize: 9,
                    },
                    header: {
                        fontSize: 16,
                        bold: true,
                    },
                    studentName: {
                        fontSize: 14,
                        bold: true,
                    },
                    courseName: {
                        fontSize: 12,
                        bold: true,
                    },
                    grayFill: {
                        background: '#f4f5f4',
                    },
                    grade: {
                        fontSize: 11,
                        bold: true,
                        color: '#2B2B2B',
                    },
                    gray: {
                        color: '#414141',
                    },
                    bold: {
                        bold: true,
                    },
                    italic: {
                        italics: true,
                    },
                },
                defaultStyle: {
                    color: 'black',
                    fontSize: 9,
                },
            };
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        refresh() {
            this.key += 1;
        },
        populate() {
            const v = this;
            const { school } = v;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            network.reports.getReportsForStaff({ url: { schoolId, schoolTermId, schoolStaffId } }, (err, results) => {
                if (err) return v.showError(err);
                v.reports = results.reports || [];
            });
        },
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        cancelConfirm() {
            this.confirmModalVisible = false;
        },
        openReport(report) {
            this.selectedReport = report;
            this.reportSearchVisible = true;
        },
        closeReportSearch() {
            this.selectedReport = null;
            this.reportSearchVisible = false;
        },
        removeReport(reportId) {
            this.reports = this.reports.filter((r) => r.reportId !== reportId);
        },
        confirmGenerateProgressReport() {
            const now = moment().format('MM/DD/YYYY hh:mm A');
            const { progressReportParams } = this;
            this.confirmHeader = `${progressReportParams.reportType}`;
            this.confirmMessage = `Generating Progress Report for school for ${now}. Reports are only made for students with enough data to generate a report.`;
            this.confirmColor = 'Green';
            this.confirmFunction = this.generateProgressReport;
            this.confirmModalVisible = true;
        },
        generateProgressReport() {
            const v = this;
            if (v.generating) return;
            v.generating = true;

            if (!this.$_userMixins_isSchoolAdmin) {
                v.progressReportParams.generatedRole = 'Teacher';
            }

            const { school, progressReportParams } = v;
            const { schoolId, schoolTermId, schoolStaffId } = school;
            const { schoolTermMarkingPeriodId } = v.$store.state.settings;
            const { schoolTermEncryptionKey } = this.$store.state.database;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                },
                body: {
                    schoolTermEncryptionKey,
                    schoolTermMarkingPeriodId,
                    progressReportParams,
                },
            };

            network.progressReports.createProgressReport(params, (err, res) => {
                v.generating = false;
                if (err) return v.showError(err);
                v.confirmModalVisible = false;
                v.populate();
            });
        },
        previewReportCard() {
            const { definition } = this;
            const pdfDocGenerator = pdfMake.createPdf(definition);
            pdfDocGenerator.getDataUrl((dataUrl) => {
                this.$refs.pdfViewModal.open(dataUrl);
            });
        },
        confirmGenerateReportCard() {
            if (!this.$_userMixins_isSchoolAdmin) return;
            const { markingPeriod } = this;
            const { markingPeriodName } = markingPeriod;
            this.confirmHeader = `Generate ${markingPeriodName} Report Cards?`;
            this.confirmMessage = 'Report Cards generations are not retroactive, any further report card grade changes will not reflect on report cards to students and parents until a re-generation.';
            this.confirmColor = 'Orange';
            this.confirmFunction = this.generateReportCard;
            this.confirmModalVisible = true;
        },
        generateReportCard() {
            const v = this;
            if (v.generating || !this.$_userMixins_isSchoolAdmin) return;
            v.generating = true;

            const { school } = v;
            const { schoolId, schoolTermId } = school;
            const { schoolTermMarkingPeriodId } = v.$store.state.settings;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolTermMarkingPeriodId,
                },
                body: {
                    announcement: v.reportCardAnnouncement || null,
                },
            };

            network.reports.generateReportCard(params, (err, res) => {
                v.reportCardAnnouncement = '';
                v.ReGenerating = false;
                v.generating = false;
                if (err) return v.showError(err);
                v.confirmModalVisible = false;
                v.populate();
            });
        },
        releaseReportCard(showOnPortfolio) {
            const v = this;
            if (v.generating || !this.$_userMixins_isSchoolAdmin) return;
            v.generating = true;

            const { school } = v;
            const { schoolId, schoolTermId } = school;
            const { schoolTermMarkingPeriodId } = v.$store.state.settings;
            const { reportCard } = v;
            const { reportId } = reportCard;
            if (!reportId) return;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolTermMarkingPeriodId,
                },
                body: { reportId, showOnPortfolio },
            };

            network.reports.releaseReportCard(params, (err, res) => {
                v.reportCardAnnouncement = '';
                v.ReGenerating = false;
                v.generating = false;
                if (err) return v.showError(err);
                v.confirmModalVisible = false;
                v.populate();
            });
        },
        openReportPdf(url) {
            this.$refs.pdfViewModal.open(url);
        },
    },
};
</script>

<style scoped>
.modal-body {
  max-height: 60vh;
  overflow-y: auto;
}
.top-right {
    position: absolute !important;
}

</style>
